import * as React from "react"
import Layout from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/privacy.scss'
import {graphql} from "gatsby";
import {Trans} from "gatsby-plugin-react-i18next";
import {Parallax} from "react-scroll-parallax";

const PrivacyPage = () => {
    return (
        <Layout>
            <title>Santaleza</title>

            <section className="main_section">

                <div className="main_logo">
                    <StaticImage class="image" src="../images/ouressence/pina_tequila_ilustracion.svg" alt="pina"/>
                    <h2 className="to">
                        <Parallax speed={50} translateY={[-15, 15]}>
                            <Trans>Privacy Policies</Trans>
                        </Parallax>
                    </h2>
                </div>

                <div className="main_image">
                    <Parallax className={'image'} speed={50} translateY={[15, -15]}>
                        <StaticImage src="../images/privacy/privacy.png" alt="don vic"/>
                    </Parallax>
                    <StaticImage className="stamp" src="../images/ouressence/sello_santaleza.svg"
                                 alt="sello santaleza"/>
                    <div className="main_scroll" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 550, behavior: `smooth`}) : ''
                    }}>
                        <p className="spettw">SCROLL DOWN</p>
                        <StaticImage src="../images/ouressence/scroll_down.svg" alt="flecha abajo"/>
                    </div>

                </div>

            </section>


            <section className="pribodysec">
                <p className="subtt resetmargin">2 Julio , 2022</p>

                <article>
                    <div className="privacy-title">
                        <div><StaticImage src="../images/privacy/vineta.png" alt="vineta"/></div>
                        <h2 className="marleftitle to resetmargin">
                            <Trans>Privacy Policies</Trans>
                        </h2>
                    </div>
                    
                    <div className="justify">
                        <p>
                            <Trans>Policies</Trans>
                        </p>
                    </div>
                </article>

                <article>
                    <div className="privacy-title">
                        <div><StaticImage src="../images/privacy/vineta.png" alt="vineta"/></div>
                        <h2 className="marleftitle to resetmargin">
                        <Trans>Cookies</Trans>
                        </h2>
                    </div>
                    <div className="justify">
                        <p>
                            <Trans>Cookies information</Trans>
                        </p>
                    </div>

                </article>
            </section>

            <section className="fourth-section">
                <div className="back-to-top" onClick={() => {
                    typeof window !== "undefined" ? window[`scrollTo`]({top: 0, behavior: `smooth`}) : ''
                }}>
                    <StaticImage className="up-arrow" src="../images/home/back-top.svg" alt="flecha arriba"/>
                    <p className="parrafo text-back">BACK <br/> TO TOP</p>
                </div>

            </section>

        </Layout>
    )
}

export const PageQuery = graphql`
     query PrivacyQuery ($language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`
export default PrivacyPage

